import React from 'react'

export default function TOS() {
  return (
    <div>
        INVERT Terms of Service
Last Updated: 11/10/2024

By creating an account with INVERT, you agree to the following Terms of Service. These terms govern your use of INVERT's website, application, and related services ("Services"). Please review these terms carefully. If you do not agree with these terms, you may not create an account or use our Services.

1. Acceptance of Terms
By accessing and using INVERT's Services, you affirm that you are at least 18 years of age and agree to abide by these Terms of Service.
INVERT reserves the right to update or modify these terms at any time without prior notice. Your continued use of our Services after any changes indicates your acceptance of the revised terms.
2. Account Registration and Security
When creating an account, you agree to provide accurate and complete information and to keep this information up to date.
You are responsible for maintaining the confidentiality of your account credentials. INVERT will not be liable for any loss or damage arising from unauthorized use of your account.
3. Consent to Receive Text Messages
By registering with INVERT, you consent to receiving text messages (SMS) from us, including messages for account registration and login verification.
Message and data rates may apply depending on your carrier and service plan. You may opt out of non-essential messages by updating your preferences; however, essential notifications related to security and verification cannot be opted out of.
4. Use of Services
You agree to use INVERT's Services only for lawful purposes and in compliance with all applicable laws and regulations.
You may not use our Services to upload, share, or transmit any material that infringes upon the rights of others, is unlawful, or violates our Community Guidelines.
5. Privacy and Data Security
INVERT takes the privacy of your personal information seriously. Please review our Privacy Policy [insert link] for details on how we collect, use, and protect your data.
6. Account Termination
INVERT reserves the right to suspend or terminate your account at any time if you violate these Terms of Service or engage in any conduct that disrupts or harms INVERT’s Services or reputation.
7. Limitation of Liability
INVERT and its affiliates will not be liable for any indirect, incidental, or consequential damages arising from your use of the Services.
    </div>
  )
}
